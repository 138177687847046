<template>
  <form class="row q-col-gutter-sm">
    <div class="col-sm-12">
      <m-group-title label="Booking information" />
      <q-card class="bg-white">
        <q-card-section>
          <div class="row q-col-gutter-sm">
            <div class="col-sm-6">
              <rental-select
                v-model="start_location"
                label="Start location"
              />
            </div>
            <div class="col-sm-6">
              <rental-select
                v-model="end_location"
                label="End Location"
              />
            </div>
          </div>

          <datetime-picker
            v-model="booking.depart_at"
            label="Start date"
          />

          <datetime-picker
            v-model="booking.arrive_at"
            label="End date"
          />

          <q-input
            v-model="booking.vehicle_group.value"
            type="text"
            stack-label
            label="Vehicle group"
          />

          <div class="row q-col-gutter-sm">
            <div class="col-sm-6">
              <q-input
                v-model="booking.rental_company_name.value"
                type="text"
                label="Rental Company Name"
              />
            </div>
            <div class="col-sm-6">
              <q-input
                v-model="booking.rental_company_reference.value"
                type="text"
                label="Rental Company Reference"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </form>
</template>

<script>
import Vue from 'vue'
import datetimePicker from './shared/datetime-picker'
import rentalSelect from './shared/rental-select'
import { MGroupTitle } from 'components/'

export default {
  name: 'BookingFormRental',
  components: {
    datetimePicker,
    rentalSelect,
    MGroupTitle
  },
  props: {
    value: Object
  },
  data () {
    return {
      booking: {
        content_type: {
          label: null,
          value: 'rental'
        },
        start_location_place_id: {
          label: null,
          value: null
        },
        start_location_branch_code: {
          label: null,
          value: null
        },
        end_location_place_id: {
          label: null,
          value: null
        },
        end_location_branch_code: {
          label: null,
          value: null
        },
        depart_at: {
          label: null,
          value: null
        },
        arrive_at: {
          label: null,
          value: null
        },
        vehicle_group: {
          label: null,
          value: null
        },
        rental_company_name: {
          label: null,
          value: null
        },
        rental_company_reference: {
          label: null,
          value: null
        }
      }
    }
  },
  computed: {
    start_location: {
      get () {
        return this.booking.start_location_branch_code || this.booking.start_location_place_id || this.booking.start_location_address
      },
      set (val) {
        if (!val) return
        if (val.type === 'branch') {
          Vue.set(this.booking, 'start_location_branch_code', val)
          delete this.booking.start_location_place_id
          delete this.booking.start_location_address
        } else if (val.type === 'address') {
          Vue.set(this.booking, 'start_location_address', val)
          delete this.booking.start_location_branch_code
          delete this.booking.start_location_place_id
        } else {
          Vue.set(this.booking, 'start_location_place_id', val)
          delete this.booking.start_location_branch_code
          delete this.booking.start_location_address
        }
      }
    },
    end_location: {
      get () {
        return this.booking.end_location_branch_code || this.booking.end_location_place_id || this.booking.end_location_address
      },
      set (val) {
        if (val.type === 'branch') {
          Vue.set(this.booking, 'end_location_branch_code', val)
          delete this.booking.end_location_place_id
          delete this.booking.end_location_address
        } else if (val.type === 'address') {
          Vue.set(this.booking, 'end_location_address', val)
          delete this.booking.end_location_branch_code
          delete this.booking.end_location_place_id
        } else {
          Vue.set(this.booking, 'end_location_place_id', val)
          delete this.booking.end_location_branch_code
          delete this.booking.end_location_address
        }
      }
    }
  },
  watch: {
    booking: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      Object.keys(this.value).forEach(key => {
        this.booking[key] = this.value[key]
      })
      this.booking.content_type = { label: null, value: 'rental' }
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
